import { Avatar, Badge, Banner, BlockStack, Button, DataTable, Divider, EmptyState, InlineStack, Layout, Link, Modal, Text, TextField } from '@shopify/polaris';
import { NoteIcon, StarFilledIcon } from '@shopify/polaris-icons';
import dateandtime from 'date-and-time';
import helpers from 'helpers';
import { QRCodeCanvas } from 'qrcode.react';
import { useCallback, useEffect, useState } from 'react';
import StarRating from 'components/starRating';
import { getOrderStatusBadge } from 'entities/order/order.component';
import __ from 'languages/index';
import { useMutateGetCustomer, useUpdateCustomer } from 'queries/customer.query';
import SkeletonLoading from './skeletonPageLoading';
export default function CustomerDetailPopup({
  onClose,
  show
}: {
  onClose: Function;
  show: string;
}) {
  const {
    mutateAsync: updateCustomer,
    isPending: updatingCustomer,
    isSuccess: updateSuccessCustomer
  } = useUpdateCustomer();
  const {
    mutate: getEntity,
    data: entityCustomer,
    isError,
    isPending: loadingCustomer
  } = useMutateGetCustomer();
  useEffect(() => {
    if (show) getEntity(show);
  }, [show]);
  /**
   * Quick note
   */
  const [showQuickNote, setShowQuickNote] = useState(false);
  const [valuetext, setValuetext] = useState('');
  useEffect(() => {
    if (entityCustomer) {
      setValuetext(entityCustomer.bio || '');
    }
  }, [entityCustomer]);
  useEffect(() => {
    if (updateSuccessCustomer === true) {
      setShowQuickNote(false);
    }
  }, [updateSuccessCustomer]);
  const saveQuickNoteButtonCallback = useCallback(async () => {
    try {
      await updateCustomer({
        user_id: entityCustomer?.user_id,
        bio: valuetext
      });
      setShowQuickNote(false);
    } catch (e) {}
  }, [entityCustomer, valuetext]);
  const handleQuickNoteChange = useCallback((newValue: string) => setValuetext(newValue), []);

  /**
   * ENd save quick note
   */

  const [diachiTinh, setDiachiTinh] = useState('');
  const [diachiHuyen, setDiachiHuyen] = useState('');
  const [diachiXa, setDiachiXa] = useState('');
  const QueryCustomerAddr = useCallback(async entityCustomer => {
    let diachiTinhData = await helpers.getDiaChinh(entityCustomer?.customer_address_city);
    setDiachiTinh(diachiTinhData?.name_with_type);
    let diachiHuyenData = await helpers.getDiaChinh(entityCustomer?.customer_address_district);
    setDiachiHuyen(diachiHuyenData?.name_with_type);
    let diachiXaData = await helpers.getDiaChinh(entityCustomer?.customer_address_ward);
    setDiachiXa(diachiXaData?.name_with_type);
  }, []);
  useEffect(() => {
    if (show) {
      QueryCustomerAddr(entityCustomer);
    }
  }, [show, entityCustomer]);
  return <>
      <Modal size="large" titleHidden open={show ? true : false} onClose={() => {
      onClose();
    }} title={'Thông tin khách hàng'}>
        {loadingCustomer ? <Modal.Section>
            <SkeletonLoading fullWidth />
          </Modal.Section> : <Modal.Section>
            {!isError ? <Layout>
                <Layout.Section variant="oneThird">
                  <div style={{
              paddingRight: '20px',
              borderRight: '1px solid #eee'
            }}>
                    <InlineStack gap={'200'} blockAlign="center">
                      <Avatar customer source={entityCustomer?.user_avatar} name={entityCustomer?.display_name} initials={entityCustomer?.display_name} />
                      <BlockStack gap="0">
                        <Text variant="headingSm" as="h4">
                          {' '}
                          {entityCustomer?.user_title && `(${__(entityCustomer?.user_title)})`}{' '}
                          {entityCustomer?.display_name || entityCustomer?.user_login || entityCustomer?.user_email}
                        </Text>
                        {entityCustomer?.user_birthday ? <Text as="span" variant="bodySm">
                            {dateandtime.format(new Date(entityCustomer?.user_birthday), 'DD-MM-YYYY')} {'  -  '}{' '}
                            {helpers.getAge(new Date(entityCustomer?.user_birthday), ' tuổi')}
                          </Text> : '-'}

                        {entityCustomer?.user_rate > 0 ? <div>
                            <StarRating num={entityCustomer?.user_rate} />
                            <Text as="span" tone="subdued">{`${entityCustomer?.user_rate_count} đánh giá`}</Text>
                          </div> : null}
                      </BlockStack>
                    </InlineStack>
                    <br />
                    <BlockStack gap={'100'}>
                      <InlineStack align="space-between">
                        <Text as="p">Đã là khách hàng</Text>
                        <Text as="p" fontWeight="bold">
                          {entityCustomer ? helpers.subtractDate(entityCustomer?.createdAt, ' ngày trước') : ''}
                        </Text>
                      </InlineStack>
                      <InlineStack align="space-between">
                        <Text as="p">Đã chi tiêu</Text>
                        <Text as="p" fontWeight="bold">
                          {entityCustomer ? helpers.formatNumber(entityCustomer?.customer_to_user?.customer_moneyspent_count, ',') : '0'} VND
                        </Text>
                      </InlineStack>
                      <InlineStack align="space-between">
                        <Text as="p">Đã mua</Text>
                        <Text as="p" fontWeight="bold">
                          {entityCustomer?.customer_to_user?.customer_order_count} đơn
                        </Text>
                      </InlineStack>

                      <InlineStack align="space-between">
                        <Text as="p">Tương tác</Text>
                        <Text as="p" fontWeight="bold">
                          {entityCustomer?._count?.user_activity} lần
                        </Text>
                      </InlineStack>

                      {entityCustomer?._count?.user_activity > 0 && <InlineStack align="space-between">
                          <Text as="p">T.tác gần nhất</Text>
                          <Text as="p" fontWeight="bold">
                            {helpers.subtractDate(entityCustomer?.user_activity[0]?.createdAt, 'ngày trước')}
                          </Text>
                        </InlineStack>}
                      <InlineStack align="space-between">
                        <Text as="p">Đã giới thiệu</Text>
                        <Text as="p" fontWeight="bold">
                          {helpers.formatNumberWithCommas(entityCustomer?.customer_to_user?.customer_referrer_count)}
                        </Text>
                      </InlineStack>

                      {entityCustomer?.referrer?.referrer_data && <>
                          <br />
                          <Divider />
                          <br />
                          <InlineStack align="space-between">
                            <Text as="p">Giới thiệu bởi</Text>
                            <Text as="p" fontWeight="bold">
                              {entityCustomer?.referrer?.referrer_data?.display_name || entityCustomer?.referrer?.referrer_data?.user_email}
                            </Text>
                          </InlineStack>
                        </>}

                      {entityCustomer?._count?.referred > 0 && <InlineStack align="space-between">
                          <Text as="p">Đã giới thiệu</Text>
                          <Text as="p" fontWeight="bold">
                            {entityCustomer?._count?.referred + ' khách'}
                          </Text>
                        </InlineStack>}
                    </BlockStack>

                    <br />
                    <Divider />
                    <br />
                    {entityCustomer?.customer_to_user?.is_vip ? <Badge tone="success" icon={StarFilledIcon}>
                        VIP
                      </Badge> : null}

                    <BlockStack gap={'100'}>
                      <InlineStack align="space-between">
                        <Text as="p">Số điện thoại</Text>
                        <Text variant="bodyMd" as="span" fontWeight="bold">
                          {entityCustomer?.user_phonenumber ?? '-'}
                        </Text>
                      </InlineStack>
                      <InlineStack align="space-between">
                        <Text as="p">Email</Text>
                        <Text variant="bodyMd" as="span" fontWeight="bold">
                          {entityCustomer?.user_email ?? '-'}
                        </Text>
                      </InlineStack>
                      <InlineStack align="space-between">
                        <Text as="p">Địa chỉ</Text>
                        <div>
                          <Text variant="bodyMd" as="span" fontWeight="bold">
                            {entityCustomer?.user_address || '-'}
                          </Text>
                          <br />
                          <Text variant="bodyMd" as="span">
                            {diachiTinh ?? null}
                          </Text>
                          <br />
                          <Text variant="bodyMd" as="span">
                            {diachiHuyen ?? null}
                          </Text>
                          <br />
                          <Text variant="bodyMd" as="span">
                            {diachiXa ?? null}
                          </Text>
                        </div>
                      </InlineStack>
                    </BlockStack>

                    {entityCustomer?.user_phonenumber && <QRCodeCanvas size={60} value={`0` + entityCustomer?.user_phonenumber} />}

                    <br />
                    <Divider />
                    <br />
                    <Banner tone="info">
                      Bạn có thể{' '}
                      <Link target="_blank" url={'/customer/view/' + entityCustomer?.user_id}>
                        <Text as="p">Xem và Chỉnh sửa thông tin khách hàng</Text>
                      </Link>
                    </Banner>
                    <br />

                    <div className="small-icon">
                      <Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
                        <NoteIcon /> Giới thiệu bản thân
                      </Text>
                    </div>
                    <br />
                    <br />
                    {showQuickNote ? <>
                        <TextField label="" multiline={2} value={valuetext} onChange={handleQuickNoteChange} autoComplete="off" maxLength={250} showCharacterCount />
                        <Button variant="plain" onClick={saveQuickNoteButtonCallback} loading={updatingCustomer}>
                          Save
                        </Button>
                        &nbsp; or &nbsp;
                        <Button variant="plain" onClick={() => {
                  setShowQuickNote(false);
                }}>
                          Cancel
                        </Button>
                      </> : <>
                        <Link removeUnderline onClick={() => setShowQuickNote(true)}>
                          <Text as="p" variant="bodyMd" tone="critical">
                            {valuetext !== '' ? valuetext : 'Chưa có mô tả nào'}
                          </Text>
                        </Link>
                      </>}
                  </div>
                </Layout.Section>
                <Layout.Section>
                  <Text as="h4" variant="headingLg">
                    Đơn hàng gần nhất
                  </Text>
                  <br />
                  {entityCustomer?.orders_orders_user_idTouser?.length > 0 ? <>
                      <DataTable columnContentTypes={['text', 'text', 'text', 'text', 'text']} headings={['PNR', 'Tình trạng', 'Thanh toán', 'Giá trị', 'Ngày tạo']} rows={entityCustomer?.orders_orders_user_idTouser?.map(orderData => {
                const {
                  order_id,
                  order_pnr,
                  order_status,
                  order_total_price,
                  payment_status,
                  createdAt
                } = orderData;
                return [<Link url={'/order/view/' + order_id} target="_blank">
                              {order_pnr}
                            </Link>, getOrderStatusBadge(order_status), <Badge tone={payment_status === 0 ? 'new' : 'success'} progress={payment_status === 1 ? 'complete' : 'incomplete'}>
                              {payment_status === 0 ? 'Chưa thanh toán' : 'Đã thanh toán'}
                            </Badge>, order_total_price, helpers.subtractDate(createdAt, ' ngày trước')];
              })} hideScrollIndicator hasZebraStripingOnData increasedTableDensity />
                    </> : <Text as="p"> Khách chưa mua đơn nào!</Text>}
                </Layout.Section>
              </Layout> : <>
                <EmptyState image={'https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/trial-ended-illustration-BEbGpE30pVU_.svg'} heading="Không phải là khách hàng">
                  Không xem được thông tin khi người này khi họ không phải là khách hàng!
                </EmptyState>
              </>}
          </Modal.Section>}
      </Modal>
    </>;
}